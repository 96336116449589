import { LoremIpsum } from "lorem-ipsum"

const _lorem = new LoremIpsum({
	sentencesPerParagraph: {
		max: 5,
		min: 3,
	},
	wordsPerSentence: {
		max: 12,
		min: 4,
	},
})

export const lorem = {
	words: (num: number) => {
		const res = _lorem.generateWords(num)

		return res.charAt(0).toUpperCase() + res.slice(1)
	},
	sentences: (num: number) => _lorem.generateSentences(num),
	paragraphs: (num: number) => _lorem.generateParagraphs(num),
	question: () => {
		const res = _lorem.generateSentences(1)

		return res.slice(0, -1) + "?"
	},
}
