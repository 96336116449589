import { ClipPathSVG } from "./WheelClipSVG"

export const CmsGuideLayout = (props: { children: React.ReactNode }) => {
	return (
		<>
			<ClipPathSVG />

			{props.children}
		</>
	)
}
